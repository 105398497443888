import Link from 'next/link';
import usePlayer from '@/hooks/usePlayer';
import clsx from 'clsx';
import { useRegisterDialog } from '@/components/registrationModalContext';
import Loader from '@/components/loader';
import Button from '@/components/button';
import { getCookie } from 'cookies-next';
import { useEffect, useState } from 'react';

interface UserActionsProps {
  onLoginClick: () => void;
  className?: string;
}

const UserActions: React.FC<UserActionsProps> = ({
  onLoginClick,
  className,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const accessToken = getCookie('access_token');
  const { toggleIsOpen } = useRegisterDialog();
  const { data: player, isLoading } = usePlayer();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Initial state (server-side and initial client render)
  const LoginRegisterUI = (
    <>
      <span
        className="hidden md:block text-sm mr-4 cursor-pointer"
        onClick={onLoginClick}
      >
        Login
      </span>
      <Button variant="primary" onClick={() => toggleIsOpen()} className="w-36">
        Register
      </Button>
    </>
  );

  // Always render a consistent initial structure
  return (
    <div className={clsx('flex items-center w-full', className)}>
      {!isMounted || !accessToken ? (
        LoginRegisterUI
      ) : isLoading ? (
        <div className="w-full flex justify-center">
          <Loader />
        </div>
      ) : player ? (
        <Link
          className="w-full border border-solid rounded-lg font-extrabold text-sm text-center min-w-fit px-5 py-3 bg-light-blue border-light-blue text-black"
          href="/profile"
        >
          Profile
        </Link>
      ) : (
        LoginRegisterUI
      )}
    </div>
  );
};

export default UserActions;
